import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";

import "../css/Stripe.css";

import LOGO from "../images/WSClogo.png";

const CheckoutForm = ({
  setCurrentPage,
  paymentDetails,
  setPaymentDetails,
}) => {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret);
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: "https://epay.wanderlustersailing.org/success",
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      setMessage("An unexpected error occurred.");
    }

    setIsLoading(false);
  };

  const paymentElementOptions = {
    layout: "tabs",
  };
  return (
    <div className="body epay">
      <div className="epay_container">
        <div className="logo_container">
          <img src={LOGO} alt="WSC" className="epay_logo_image" />
        </div>
        <div className="epay_title_container">
          <h2 className="epay_title_text">Wanderlusters Sailing Club</h2>
          <h5 className="epay_sub_title_text">Check Out</h5>
        </div>

        <div className="epay_amount_details">
          <div className="epay_amount_title">
            <h3 className="epay_amounts_title_text">Summary of Charges</h3>
          </div>
          <div className="epay_amount_table">
            <div className="epay_amounts_left">
              <h3 className="epay_amount_table_text"> Payment Amount </h3>
              <h3 className="epay_amount_table_text">
                Electronic Processing Fee
              </h3>
              <h3 className="epay_amount_table_text">Total Charge Amount</h3>
            </div>
            <div className="epay_amounts_right">
              <h3 className="epay_amount_table_text">
                {isNaN(paymentDetails.Amount)
                  ? "$0.00"
                  : "$" + paymentDetails.Amount}
              </h3>
              <h3 className="epay_amount_table_text">
                {isNaN(paymentDetails.Surcharge)
                  ? "$0.00"
                  : "$" + paymentDetails.Surcharge}
              </h3>
              <h3 className="epay_amount_table_text">
                {isNaN(paymentDetails.Total)
                  ? "$0.00"
                  : "$" + paymentDetails.Total.toFixed(2)}
              </h3>
            </div>
          </div>
        </div>
        <div className="checkout_container">
          <form id="payment-form" onSubmit={handleSubmit}>
            <PaymentElement
              id="payment-element"
              options={paymentElementOptions}
            />
            <button disabled={isLoading || !stripe || !elements} id="submit">
              <span id="button-text">
                {isLoading ? (
                  <div className="spinner" id="spinner"></div>
                ) : (
                  "Pay now"
                )}
              </span>
            </button>
            {/* Show any error or success messages */}
            {message && <div id="payment-message">{message}</div>}
          </form>
        </div>
      </div>
    </div>
  );
};

export default CheckoutForm;
