import React, { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./components/CheckoutForm";
import PaymentDetailsForm from "./components/PaymentDetailsForm";

// const stripePromise = loadStripe(
//   "pk_test_51McEyAK97cmgjywVRCEKXIZPChANShMcHvCj1Hj4UcpU0w1igaBVTqHeoKQdUgZcWysMg9se7xqUEpPv3E8TjUbw00hVUJ0IYE"
// );
const stripePromise = loadStripe(
  "pk_live_51McEyAK97cmgjywVXoJ8xfb4TyDyHxGsE2g7r4M19UotH2buIlK2tOUVG8Xhl2KAyumDBvxZFou3C4eR7lVyisrX00wHuF7but"
);

const CreatePayment = ({ paymentDetails, setPaymentDetails }) => {
  const [currentPage, setCurrentPage] = useState("Payment");
  const [clientSecret, setClientSecret] = useState("");
  const [errors, setErrors] = useState({
    Name: false,
    Phone: false,
    Email: false,
    Amount: false,
  });

  const validateInputs = () => {
    let name = false;
    let phone = false;
    let email = false;
    let amount = false;
    let valid = true;

    if (paymentDetails.Name === "") {
      name = true;
      valid = false;
    }

    if (paymentDetails.Phone === "") {
      phone = true;
      valid = false;
    }
    if (paymentDetails.Email === "") {
      email = true;
      valid = false;
    }
    if (parseInt(paymentDetails.Amount) < 1.0) {
      amount = true;
      valid = false;
    }

    setErrors({
      Name: name,
      Phone: phone,
      Email: email,
      Amount: amount,
    });

    return valid;
  };

  const checkout = () => {
    const valided = validateInputs();
    if (valided) {
      fetch("/create-payment-intent", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(paymentDetails),
      })
        .then((res) => res.json())
        .then((data) => {
          console.log(data.clientSecret);
          setClientSecret(data.clientSecret);
          window.scroll(0, 0);
          setCurrentPage("Checkout");
        });
    } else {
      window.scroll(0, 0);
    }
  };

  const appearance = {
    theme: "stripe",
  };
  const options = {
    clientSecret,
    appearance,
  };
  switch (currentPage) {
    default:
    case "Payment":
      return (
        <div className="App">
          <PaymentDetailsForm
            checkout={checkout}
            paymentDetails={paymentDetails}
            setPaymentDetails={setPaymentDetails}
            errors={errors}
            setErrors={setErrors}
          />
        </div>
      );
    case "Checkout":
      return (
        <div className="App">
          <Elements options={options} stripe={stripePromise}>
            <CheckoutForm
              paymentDetails={paymentDetails}
              setPaymentDetails={setPaymentDetails}
              setCurrentPage={setCurrentPage}
            />
          </Elements>
        </div>
      );
  }
};

export default CreatePayment;
