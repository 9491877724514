import React, { useEffect, useState } from "react";

import LOGO from "../images/WSClogo.png";

const PaymentDetailsForm = ({
  checkout,
  paymentDetails,
  setPaymentDetails,
  errors,
  setErrors,
}) => {
  const [detailLabel, setDetailLabel] = useState("");

  useEffect(() => {
    if (paymentDetails.Reason === "Club Cruise") setDetailLabel("Cruise Date");
    if (paymentDetails.Reason === "Sailing_Payment_Captain")
      setDetailLabel("Sail Date(s)");
    if (paymentDetails.Reason === "Invoice") setDetailLabel("Invoice #");
    if (paymentDetails.Reason === "Annual_Membership_Dues")
      setDetailLabel("Captain or Crew Member?");
    if (paymentDetails.Reason === "Other") setDetailLabel("Details of payment");
  }, [paymentDetails.Reason]);

  const handleInput = (e) => {
    if (e.target.name === "Amount") {
      if (e.target.value > 1.0) {
        console.log(true);
        setErrors({ ...errors, Amount: false });
      }
      const amt = parseFloat(e.target.value);
      const amt2 = amt.toFixed(2);
      const surCharge = parseFloat(amt * 0.032 + 0.3);
      const surCharge2 = parseFloat(surCharge).toFixed(2);
      const total = parseFloat(amt) + parseFloat(surCharge2);

      setPaymentDetails({
        ...paymentDetails,
        Amount: amt2,
        Surcharge: surCharge2,
        Total: total,
      });
    } else {
      if (e.target.name === "Name" && e.target.value.length > 0) {
        setErrors({ ...errors, Name: false });
      }
      if (e.target.name === "Phone" && e.target.value.length > 0) {
        setErrors({ ...errors, Phone: false });
      }
      if (e.target.name === "Email" && e.target.value.length > 0) {
        setErrors({ ...errors, Email: false });
      }
      setPaymentDetails({ ...paymentDetails, [e.target.name]: e.target.value });
    }
  };

  return (
    <div className="body epay">
      <div className="epay_container">
        <div className="logo_container">
          <img src={LOGO} alt="WSC" className="epay_logo_image" />
        </div>
        <div className="epay_title_container">
          <h2 className="epay_title_text">Wanderlusters Sailing Club</h2>
          <h5 className="epay_sub_title_text">Electronic Payment Form</h5>
          <p className="epay_instruction_text">
            Please fill out the below form to make an electronic payment to
            Wanderlusters Sailing Club. Please note that the processing fees
            charged to the club will be added to the amount that you are paying.
            (3.2% of Amount plus a .30 transaction fee).
          </p>
          <p className="epay_instruction_text">
            If you would like avoid the processing fees, please mail payments to
            WSC, C/O Lionel Phillips, Treasurer, 7178 Airlie Road, Warrenton VA
            20187
          </p>
        </div>
        <div className="payment_details_form_container">
          <div className="w-form">
            <form className="epay_payment_details_form">
              <label htmlFor="name" className="epay_form_field">
                Name
                <span>
                  {errors.Name && (
                    <div className="form_error_message">
                      Full Name is Required
                    </div>
                  )}
                </span>
              </label>
              <input
                type="text"
                className="epay_form_Text_input"
                maxLength="256"
                name="Name"
                placeholder=""
                id="Name"
                onChange={handleInput}
              />
              <label htmlFor="Phone" className="epay_form_field">
                Phone{" "}
                <span>
                  {errors.Phone && (
                    <div className="form_error_message">
                      A Phone number is Required
                    </div>
                  )}
                </span>
              </label>
              <input
                type="text"
                className="epay_form_Text_input"
                maxLength="256"
                name="Phone"
                placeholder=""
                id="Phone"
                onChange={handleInput}
              />
              <label htmlFor="Email" className="epay_form_field">
                Email Address{" "}
                <span>
                  {errors.Email && (
                    <div className="form_error_message">
                      An Email Address is Required
                    </div>
                  )}
                </span>
              </label>
              <input
                type="email"
                className="epay_form_Text_input"
                maxLength="256"
                name="Email"
                placeholder=""
                id="Email"
                onChange={handleInput}
              />
              <label htmlFor="Reason" className="epay_form_field">
                Reason For Payment
              </label>
              <select
                id="Reason"
                name="Reason"
                className="epay_form_Select_input"
                onChange={handleInput}
              >
                <option value="">Select one...</option>
                <option value="Sailing_Payment_Captain">
                  Sailing Trip - Captain Payment
                </option>
                <option value="Club Cruise">Club Cruise </option>
                <option value="Invoice">Invoice</option>
                <option value="Other">Other</option>
              </select>
              {paymentDetails.Reason !== "" || paymentDetails === undefined ? (
                <>
                  <label htmlFor="Detail" className="epay_form_field">
                    {detailLabel}
                  </label>
                  <input
                    type="text"
                    className="epay_form_Text_input"
                    maxLength="256"
                    name="Detail"
                    placeholder=""
                    id="Detail"
                    onChange={handleInput}
                  />
                  <label
                    htmlFor="Additional_Details"
                    className="epay_form_field"
                  >
                    Additional Details
                  </label>
                  <textarea
                    placeholder="Enter any additional details pertaining to this payment"
                    maxLength="5000"
                    rows={8}
                    id="Additional_Details"
                    name="Additional_Details"
                    className="epay_form_TextArea_input"
                    onChange={handleInput}
                  ></textarea>
                  <label htmlFor="Amount" className="epay_form_field">
                    Amount
                    {errors.Amount && (
                      <div className="form_error_message">
                        Please enter a valid amount to charge.
                      </div>
                    )}
                  </label>
                  <input
                    type="text"
                    className="epay_form_Text_input"
                    maxLength="10"
                    name="Amount"
                    id="Amount"
                    required={true}
                    onChange={handleInput}
                    placeholder="Enter dollars and cents only (Example: 100.00)"
                  />
                </>
              ) : null}
            </form>
          </div>
        </div>
        {paymentDetails.Reason !== "" || paymentDetails === undefined ? (
          <>
            <div className="epay_amount_details">
              <div className="epay_amount_title">
                <h3 className="epay_amounts_title_text">Summary of Charges</h3>
              </div>
              <div className="epay_amount_table">
                <div className="epay_amounts_left">
                  <h3 className="epay_amount_table_text"> Payment Amount </h3>
                  <h3 className="epay_amount_table_text">
                    Electronic Processing Fee
                  </h3>
                  <h3 className="epay_amount_table_text">
                    Total Charge Amount
                  </h3>
                </div>
                <div className="epay_amounts_right">
                  <h3 className="epay_amount_table_text">
                    {isNaN(paymentDetails.Amount)
                      ? "$0.00"
                      : "$" + paymentDetails.Amount}
                  </h3>
                  <h3 className="epay_amount_table_text">
                    {isNaN(paymentDetails.Surcharge)
                      ? "$0.00"
                      : "$" + paymentDetails.Surcharge}
                  </h3>
                  <h3 className="epay_amount_table_text">
                    {isNaN(paymentDetails.Total)
                      ? "$0.00"
                      : "$" + paymentDetails.Total.toFixed(2)}
                  </h3>
                </div>
              </div>
            </div>
            <div className="checkout_button_container">
              <div onClick={checkout} className="checkout_button">
                Check Out
              </div>
            </div>
            <div className="checkout_container"></div>
          </>
        ) : null}
      </div>
    </div>
  );
};

export default PaymentDetailsForm;
