import React from "react";
import LOGO from "../images/WSClogo.png";

const SuccessfulCharge = () => {
  const queryParameters = new URLSearchParams(window.location.search);
  const transID = queryParameters.get("payment_intent");
  return (
    <>
      <div className="body epay">
        <div className="epay_container">
          <div className="logo_container">
            <img src={LOGO} alt="WSC" className="epay_logo_image" />
          </div>
          <div className="epay_title_container">
            <h2 className="epay_title_text">Wanderlusters Sailing Club</h2>
            <h5 className="epay_sub_title_text">
              Electronic Payment Successful
            </h5>
            <div className="epay_trans_id">Transaction ID # {transID}</div>
            <p className="epay_instruction_text">
              A receipt of this transaction will be emailed to you.
            </p>
          </div>
          <div className="checkout_button_container">
            <a
              href="https://epay.wanderlustersailing.org/"
              className="Another_Charge_Button"
            >
              Another Charge
            </a>
          </div>
          <div className="checkout_container"></div>
        </div>
      </div>
    </>
  );
};

export default SuccessfulCharge;
