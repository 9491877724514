import React, { useState } from "react";
import { Routes, Route, Outlet } from "react-router-dom";
import CreatePayment from "./CreatePayment";
import SuccessfulCharge from "./components/SuccessfulCharge";
import ChargeError from "./components/ChargeError";
import "./css/App.css";

function App() {
  const [paymentDetails, setPaymentDetails] = useState({
    Name: "",
    Phone: "",
    Email: "",
    Reason: "",
    Detail: "",
    Additional_Details: "",
    Amount: 0.0,
    Surcharge: 0.0,
    Total: 0.0,
  });

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route
            index
            element={
              <CreatePayment
                paymentDetails={paymentDetails}
                setPaymentDetails={setPaymentDetails}
              />
            }
          />
          <Route
            path="success"
            element={
              <SuccessfulCharge
                paymentDetails={paymentDetails}
                setPaymentDetails={setPaymentDetails}
              />
            }
          />
          <Route path="error" element={<ChargeError />} />
          <Route path="*" element={<create />} />
        </Route>
      </Routes>
    </div>
  );
}

function Layout() {
  return (
    <>
      <Outlet />
    </>
  );
}

export default App;
